import icon from "../assets/icon.png";
import logo from "../assets/logo.png";
import logo2 from "../assets/logo2.png";
import profile from "../assets/profile-01.png";

export default {
  icon,
  logo,
  logo2,
  profile,
};
